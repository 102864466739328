import type {GetServerSideProps, NextPage} from 'next'
import Image from 'next/image'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {Button} from '@components/button';
import {InfoPanel} from '@components/info-panel';
import {random} from 'lodash'
import {
    faEnvelope as faEnvelopeLight,
    faHandHoldingMedical,
    faHandsBubbles,
    faLocationCrosshairs,
    faPhone,
    faUtensils
} from '@fortawesome/pro-light-svg-icons';
import {useCallback, useEffect, useRef, useState} from 'react';
import {List} from '@components/list';
import {StickyHelper} from '@components/sticky-helper';
import {SalaryCalculatorData, SalaryCalculatorProvider} from '@components/salary-calculator/provider';
import {SalaryCalculator} from '@components/salary-calculator';
import {useMedia, useSessionStorage} from 'react-use';
import headerImage1 from '../public/img/header_blob1.png';
import headerImage2 from '../public/img/header_blob2.png';
import headerImage3 from '../public/img/header_blob3.png';
import headerImage4 from '../public/img/header_blob4.png';
import headerImage5 from '../public/img/header_blob5.png';
import headerImage6 from '../public/img/header_blob6.png';

import {PageFooter} from '@components/page-footer';
import {useRouter} from 'next/router';
import Link from 'next/link';
import {animated, config, useSpring} from 'react-spring';
import {FAQPanel} from '@components/faq-panel';
import {PageHeader} from '@components/page-header';
import {Accordion, AccordionItem} from '@components/accordion';
import Head from 'next/head';
import {useCanonical} from 'lib/util';


const Blob2 = () => (<svg width="1152" height="1033" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M402.336 15.203S232.934 20.515 164.29 26.52C95.663 32.525-13.724 86.666 3.997 289.22c22.608 258.418 421.338 270.458 499.538 373.872 98.022 132.19 85.502 384.294 294.829 365.98 137.27-12.01 170.309-108.74 170.309-108.74 120.546-251.82 180.82-426.966 180.82-525.437 0-147.708-63.884-392.641-282.083-392.641-218.2 0-465.074 12.95-465.074 12.95Z"
        stroke="#FFF" strokeWidth="4" fill="none" fillRule="evenodd"/>
</svg>)
const Blob1 = () => (<svg width="1170" height="1392" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1162.306 506.586c-26.215 127.276-75.738 147.403-180.9 167.58-105.163 20.179-238.91.627-267.966 100.347-75.375 213.172 74.79 240.543 26.917 419.206-47.872 178.66-226.76 217.768-346.947 185.564-186.138-59.062-305.139-158.66-357.004-298.792-77.797-210.2-9.693-615.584 71.85-790.452C189.798 115.17 357.998-48.346 648.72 16.742c290.724 65.088 562.137 244.182 513.585 489.844Z"
        stroke="#FFF" strokeWidth="4" fill="none" fillRule="evenodd"/>
</svg>)
const Blob3 = () => (<svg width="729" height="1182" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M156.836 270.242C314.875 201.992 358.992 64.92 419.784 27.664c60.792-37.255 163.89-55.85 269.694 107.072 134.981 207.853-146.174 443.69-142.14 566.196 6.555 155.49 163.232 338.604 1.204 443.826-106.253 69.002-186.776 14.8-186.776 14.8-148.068-89.002-252.034-221.342-311.899-397.019-89.797-263.516-51.07-424.047 106.969-492.297Z"
        stroke="#F9B25C" strokeWidth="4" fill="none"/>
</svg>)
const Blob4 = () => (<svg width="777" height="1433" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M59.438 1279.102C33.359 1211.398 4 1014.789 88.475 937.755c84.476-77.034 183.949-146.655 174.072-227.098C228.68 484.586 21.148 468 4 300-13.148 132 94.478 64.572 213.468 28.193 402.62-20.357 556.318 1.045 674.561 92.4c177.364 137.033 80.58 351.03-1.66 449.089-82.24 98.059-247.486 219.272-175.245 338.135 72.24 118.863 173.469 459.082-69.683 532.129-162.107 48.68-284.952 4.463-368.535-132.651Z"
        stroke="#F9B25C" strokeWidth="4" fill="none"/>
</svg>)
const Blob5 = () => (<svg width="777" height="1435" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M717.322 155.887c26.078 67.703 55.437 264.312-29.038 341.346-84.475 77.034-183.949 146.655-174.072 227.099 33.868 226.07 241.399 242.656 258.547 410.656 17.149 168-90.478 235.429-209.468 271.808-189.152 48.55-342.85 27.148-461.092-64.207-177.365-137.033-80.58-351.03 1.66-449.09 82.239-98.058 247.485-219.271 175.244-338.135-72.24-118.863-173.469-459.082 69.688-532.115 243.156-73.034 342.453 64.935 368.53 132.638Z"
        stroke="#F9B25C" strokeWidth="4" fill="none"/>
</svg>)


const perks = ['Góða upplæring', 'Spennandi og mennandi gerandisdag', 'Ávirkan á menningina av tænastum', 'Góðar umstøður og fjølbroyttar avbjóðingar', 'Møguleika at seta tín dám á']
const requirements =  ['Útbúgving sum sjúkrarøktarfrøðingur', 'Útbúgving sum heilsurøktari', 'Útbúgving sum heilsuhjálpari', 'Áhuga at veita eldri borgarum hjálp og røkt', 'Áhuga at arbeiða við heilsustimbran, fyribyrgjan og endurvenjing', 'Evni at samskifta í skrift og talu', 'Hug at arbeiða í tvørfakligum toymi í menning', 'Jaligan hugburð', 'KT-førleika á brúkarastøði']


const headerImages = [headerImage1, headerImage2, headerImage3, headerImage4, headerImage5, headerImage6]

const Home: NextPage<PageProps> = (props: PageProps) => {
    const router = useRouter()
    const [value, setValue] = useSessionStorage<SalaryCalculatorData | null>('hours', null)
    useEffect(() => {
        router.prefetch('/umsokn')
    })

    const calculatorRef = useRef<HTMLDivElement>(null)
    const scrollToCalculator = useCallback(() => {
        if (calculatorRef.current) {
            calculatorRef.current.scrollIntoView()
        }
    }, [calculatorRef.current])
    const onSubmit = async (values: SalaryCalculatorData) => {
        setValue(values)
        await router.push('/umsokn')
    }
    const canonicalUrl = useCanonical()
    return (
        <main className="antialiased w-full overflow-hidden">
            <Head>
                <title>Roðin søkir røktarfólk</title>
                <link rel="canonical" href={canonicalUrl}/>
            </Head>
            <StickyHelper/>
            <header className="relative bg-gradient-slight from-purple to-red overflow-hidden">
                <PageHeader/>
                <div
                    className="px-4 mx-auto md:mt-32 pb-12 md:pb-36 max-w-screen-xl flex flex-col-reverse md:grid md:grid-cols-2 gap-[24px]">
                    <div className="font-semibold text-white sm:text-xl">
                        <h1 className="font-black text-[60px] md:text-[100px] leading-none mb-8">
                            Ynskir tú eitt starv í vikuskiftum?
                        </h1>
                        <p className="mb-11">Roðin søkir eftir røktarfólkum at arbeiða í vikuskiftum.
                            <br/>
                            <br/>
                            Vit gleða okkum at hoyra frá tær!
                        </p>
                        <div className="grid grid-cols-2 gap-6">
                            <Link passHref={true} href="/umsokn"><Button className="btn-shake">Søk her!</Button></Link>
                            <Button onClick={() => scrollToCalculator()}
                                    icon={<FontAwesomeIcon width={19} height={19} icon={faArrowDown}/>}>Rokna
                                løn</Button>
                        </div>
                    </div>

                    <div
                        className="md:-mt-32 -mt-[64px] relative w-[328px] md:right-0 -right-16 sm:w-[420px] md:pb-32 md:w-[872px] ml-auto">
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <Image src={headerImages[props.headerImageId]} layout={'responsive'} width={872} height={606}
                               priority={true}/>
                        {/*<div className="absolute inset-0 bg-white mix-blend-darken">*/}
                        {/*    <Image src="/blob_primary.svg" layout={'fill'}/>*/}
                        {/*    <div className="absolute inset-0">*/}
                        {/*        <Image src="/header-bg/9.jpg" layout="fill" objectFit={'cover'} alt="people"*/}
                        {/*               className="mix-blend-lighten"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </header>
            <div className="relative bg-paper" role="region">
                <div className="py-12 md:py-28 px-4 max-w-[52rem] mx-auto">
                    <h2 className="mb-6 text-center text-[60px] leading-none md:text-[100px] font-black text-rodin-900">
                        Røktar&shy;starvsfólk
                    </h2>
                    <div className="text-gray-800 mb-12 text-center">
                        Í Roðanum verður dentur lagdur á, at liva eitt lív, sum gevur meining og varðveitir samleikan
                        hjá búfólkum og brúkarum. Her starvast sjúkrarøktarfrøðingar, heilsurøktarar, heilsuhjálparar og
                        hjálparfólk. Vit stremba eftir at arbeiða viðurkennandi og at skapa ein gerandisdag, har
                        húsfólk, brúkarar, starvsfólk og avvarðandi trívast, mennast og hava tað gott.
                    </div>
                    <div className="grid lg:grid-cols-2 gap-x-24 gap-y-12">
                        <div>
                            <h3 className="text-rodin-900 font-bold text-lg mb-6 ml-8">Tú fært</h3>
                            <List.Basic items={perks}/>
                        </div>
                        <div>
                            <h3 className="text-rodin-900 font-bold text-lg mb-6 ml-8">Førleikakrøv</h3>
                            <List.Basic items={requirements}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative bg-gradient-slant from-purple to-[#c72123] py-12 md:py-28" role="region"
                 aria-labelledby="calculator-title"
                 ref={calculatorRef}>
                <SalaryCalculatorProvider onSubmit={onSubmit} initialValues={value}>
                    <div className="px-4 mx-auto max-w-screen-xl ">
                        <div className="flex flex-col items-center mb-20">
                            <h2 className="mb-10 text-center text-[60px] leading-none md:text-[100px] font-black text-white"
                                id="calculator-title">
                                Rokna tína løn her
                            </h2>
                            <p className="text-white text-center grow-1 max-w-[824px]">
                                Við at útfylla ynskta tímatalið um vikuna, kanst tú rokna út hvørja løn tú hevði fingið.
                                <br/>
                                Útrokningin vísir grundlønina uttan 12% frítíðarløn og 15% í eftirløn.
                            </p>
                        </div>
                        <SalaryCalculator/>
                        <p className="text-white sm:pt-8 pt-4 text-opacity-50 flex-0 max-w-lg mx-auto text-center text-sm">*Lønarútrokningin
                            er bert
                            vegleiðandi.
                            Størvini verða sett sambært sáttmála við
                            avvarðandi fakfelag og KAF / Fíggjarmálaráðið.</p>
                        <p className="text-white pt-4 text-opacity-50 flex-0 mx-auto text-center text-sm">**Útrokningin av mánaðarlønini er treytað av, um tú nært at arbeiða eitt ella tvey vikuskiftir í einum og sama mánaða</p>
                    </div>
                </SalaryCalculatorProvider>
            </div>
            <div className="bg-paper text-rodin-900" role="region" aria-labelledby="about-title">
                <div className="px-4 py-36 mx-auto max-w-screen-xl grid md:grid-cols-2 gap-[24px]">
                    <div>
                        <h2 className="text-[65px] lg:text-[100px] text-center sm:text-left leading-none mb-10 font-black"
                            id="about-title">
                            Um okkum
                        </h2>
                        <div
                            className="leading-snug sm:leading-relaxed sm:text-[1.07rem] sm:text-left text-center space-y-6 mb-12">
                            <p>
                                Runavíkar -, Nes – og Sjóvar kommuna – hava skipað javnbjóðis samstarv um heimatænastu
                                og eldrarøkt v.m., sum við lóg varð flutt kommununum at umsita tann 1. januar 2015.
                                Roðin umsitir økistænastuna í nevndu kommunum.
                            </p><p>
                            Mál okkara er støðugt at menna tænastuna og at ganga á odda, tá ræður um at veita teim, ið
                            tørva, eina fjøltáttaða og dygdargóða tænastu – hetta í góðum samstarvi við brúkaran og
                            avvarðandi hansara.
                        </p>
                        </div>
                        <div
                            className="hidden md:flex flex-col sm:gap-y-6 gap-y-2 items-center sm:items-start md:justify-start justify-center text-sm sm:text-xl lg:text-2xl">
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faPhone} className="mr-8 flex-0 text-gold-500"/>
                                <a href="tel:00298478200" className="font-black text-rodin-900">+298 47 82
                                    00</a>
                            </div>
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faEnvelopeLight} className="mr-8 flex-0 text-gold-500"/>
                                <a href="mailto:rodin@rodin.fo"
                                   className="font-black text-rodin-900">rodin@rodin.fo</a>
                            </div>
                            <address className="flex items-center not-italic">
                                <FontAwesomeIcon icon={faLocationCrosshairs}
                                                 className="mr-8 flex-0 text-gold-500"/>
                                <a href="https://goo.gl/maps/fWwndzVPs8wUk3R57"
                                   target="_blank"
                                   rel="noreferrer"
                                   className="font-black  text-rodin-900">Friðartún 1, Runavík</a>
                            </address>
                        </div>
                    </div>
                    <div className="md:max-w-[420px] justify-self-end w-full">
                        <FAQPanel>
                            <Accordion>
                                <AccordionItem title="Skal eg arbeiða í einum toymi?">
                                    Ja, tú ert ongantíð einsamøll/einsamallur á vakt.
                                </AccordionItem>
                                <AccordionItem title="Hvønn kann eg spyrja?">
                                    Starvsfelagar og deildarleiðsla eru altíð fús at svara spurningum og læra frá sær.
                                    Eisini ert tú vælkomin at venda tær til Beintu :)
                                </AccordionItem>
                                <AccordionItem title="Hvat er Roðin?">
                                    <p>
                                        Runavíkar -, Nes – og Sjóvar kommuna – hava skipað javnbjóðis samstarv um
                                        heimatænastu og eldrarøkt v.m., sum við lóg varð flutt kommununum at umsita tann
                                        1. januar 2015. Roðin umsitir økistænastuna í nevndu kommunum.
                                    </p>
                                    <br/>
                                    <p>
                                        Mál okkara er støðugt at menna tænastuna og at ganga á odda, tá ræður um at
                                        veita teim, ið tørva, eina fjøltáttaða og dygdargóða tænastu – hetta í góðum
                                        samstarvi við brúkaran og avvarðandi hansara.
                                    </p>
                                    <br/>
                                    <p>
                                        Tænasturnar eru skipaðar eftir lóg um heimatænastu, eldrarøkt v.m., sum sæst
                                        her. Roðin veitir borgarum við tørvi eina samskipaða heildartænastu. Endamálið
                                        við tænastunum er, at: Fyribyrgja sjúku, stimbra sálarliga og likamliga heilsu
                                        og trivna borgarans, endurmenna mistan førleika, stimbra møguleika hins einstaka
                                        at klára seg sjálvan, veita røkt og umsorgan.
                                    </p>
                                </AccordionItem>
                                <AccordionItem title="Arbeiða ung fólk har?">
                                    Ja, starvsfólkini eru í øllum aldri og eisini eru nógv ung.
                                </AccordionItem>
                                <AccordionItem title="Hvussu gamal skal man vera?">
                                    Fyri at starvast í røktini skal ein vera 18 ár ella eldri. Ert tú yngri, vilja vit
                                    eisini fegin tosa við teg um møguligar tillagaðar uppgávur
                                </AccordionItem>
                                <AccordionItem title="Kann praktikktíðin styttast?">
                                    Um tú hevur eitt ára arbeiðsroynd fulla tíð ella tvey ár á hálva tíð innan røkt
                                    innanfyri seinastu fimm árini, kann ein praktikk verða lopin um og sostatt verður
                                    lærutíðin stytt við uml. trimum mánaðum. Her er talan um heilsuhjálparaútbúgvingina.
                                </AccordionItem>
                            </Accordion>
                        </FAQPanel>
                    </div>
                </div>
                <div className="px-4 mx-auto py-[50px] max-w-screen-xl grid md:grid-cols-2 gap-[24px]">
                    <div
                        className="md:hidden flex flex-col gap-y-2 items-center justify-center text-sm">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faPhone} className="mr-2 flex-0 text-gold-500"/>
                            <a href="tel:00298478200" className="font-black text-rodin-900">+298 47 82
                                00</a>
                        </div>
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faEnvelopeLight} className="mr-2 flex-0 text-gold-500"/>
                            <a href="mailto:rodin@rodin.fo"
                               className="font-black text-rodin-900">rodin@rodin.fo</a>
                        </div>
                        <address className="flex items-center not-italic">
                            <FontAwesomeIcon icon={faLocationCrosshairs}
                                             className="mr-2 flex-0 text-gold-500"/>
                            <a href="https://goo.gl/maps/fWwndzVPs8wUk3R57"
                               target="_blank"
                               rel="noreferrer"
                               className="font-black  text-rodin-900">Friðartún 1, Runavík</a>
                        </address>
                    </div>

                </div>
            </div>
            <PageFooter/>
        </main>
    )
}

interface PageProps {
    headerImageId: number;
}

// noinspection JSUnusedGlobalSymbols
export const getServerSideProps: GetServerSideProps<PageProps> = async (context) => {
    const headerImageId = random(0, 5)
    return {
        props: {
            headerImageId
        }
    }
}

export default Home
